<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
            'product_ProductId',
            'product_ProductsNow',
            'product_InTransit',
            'product_Reserv',
            'product_FBM',
            'product_Damaged',
          ])"></div>
      <CardLeftBlock
          v-if="product"
          :backgroundImage="'orders'"
          :name="$t('product_ProductId.localization_value.value')"
          :value="'#' + product.productInstance.id"
      >
        <template slot="body">
          <div class="card-detail-left__date mt-1 mb-3 fsz18">
            {{ product.productInstance.name }}
          </div>
        </template>
        <template slot="button">
          <div class="card-detail-left__date">
            {{ product.productInstance.created_at | moment("DD MMM, YYYY") }}
          </div>
        </template>


        <template slot="footer">
          <div class="block-table">
            <div class="block-table__name mt-4">
              {{ $t('product_ProductsNow.localization_value.value') }}
            </div>
            <div class="block-table__row"
                 v-if="getProductCountInfo !== undefined"
            >
              <div class="block-table__col block-table__col--50 flex-row">
                <div class="block-table__label mr-2">{{ $t('product_InTransit.localization_value.value') }}</div>
                <div class="block-table__content">
                  <span class="table-link">
                  {{ getProductCountInfo.transit }}
                  </span>
                </div>
              </div>
              <div class="block-table__col block-table__col--50 flex-row">
                <div class="block-table__label mr-2">{{ $t('product_Reserv.localization_value.value') }}</div>
                <div class="block-table__content">
                  <span class="table-link">
                  {{ getProductCountInfo.reserve }}
                  </span>
                </div>
              </div>
              <div class="block-table__col block-table__col--50 flex-row">
                <div class="block-table__label mr-2">{{ $t('product_FBM.localization_value.value') }}</div>
                <div class="block-table__content">
                  <span class="table-link">
                  {{ getProductCountInfo.count_in_storage.count_normal }}
                  </span>
                </div>
              </div>
              <div class="block-table__col block-table__col--50 flex-row">
                <div class="block-table__label mr-2">{{ $t('product_Damaged.localization_value.value') }}</div>
                <div class="block-table__content">
                  <span class="table-link">
                  {{ getProductCountInfo.count_in_storage.count_damage }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <!--        <template slot="header">-->
        <!--          <div class="order-create__head">-->
        <!--            <div class="order-create__head-title color-gray"-->
        <!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--              <div class="admin-edit" @click="editTranslate(['product_BackProducts'])"></div>-->
        <!--              {{ $t('fba_newFbaOrder.localization_value.value') }}-->
        <!--              &lt;!&ndash;              New FBA Order&ndash;&gt;-->
        <!--            </div>-->
        <!--            <div class="order-create__head-txt"-->
        <!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--              <div class="admin-edit" @click="editTranslate(['product_BackProducts'])"></div>-->
        <!--              {{ $t('fba_pleaseFillOut.localization_value.value') }}-->
        <!--              &lt;!&ndash;              Please, fill out all the information below to complete the order for the recepient&ndash;&gt;-->
        <!--            </div>-->

        <!--            <div class="order-create__head-steps product-progress-steps"-->
        <!--                 v-if="$store.getters.getIsAdminRights === 'user'"-->
        <!--            >-->
        <!--              <ProgressSteps-->
        <!--                  :options="progressStepsSetting"-->
        <!--                  :step="activeStep"-->
        <!--                  @changeStep="stepChange"-->
        <!--                  @nextPageHandler="nextPageHandler"-->
        <!--              />-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </template>-->

        <template slot="body">


          <div class="order-create__section">

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col">
                <div class="block-table__name block-table__name--mobile  mb-4"
                     v-if="$store.getters.getProductsReport.length > 0"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['reportCells_ProductsFromCells'])"></div>
<!--                  {{ $t('reportCells_ProductsFromCells.localization_value.value') }}-->

                  {{getUserCellName}}
                </div>


                <template v-if="$store.getters.getProductsReport.length > 0 && $store.getters.getProductsReportLoading !== true">
                  <div class="site-table-wrap table-small"
                       v-if="!isMobileFunc()"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
                        'reportCells_Date',
                        'reportCells_User',
                        'reportCells_ItemsQtyBefore',
                        'reportCells_ItemsQty',
                        'reportCells_ItemsQtyAfter',
                      ])"></div>
                    <table class="site-table">
                      <thead>
                      <tr>
  <!--                      <th>{{ $t('reportCells_Cell.localization_value.value') }}</th>-->
                        <th>{{ $t('reportCells_Date.localization_value.value') }}</th>
                        <th>{{ $t('reportCells_User.localization_value.value') }}</th>
                        <th>{{ $t('reportCells_ItemsQtyBefore.localization_value.value') }}</th>
                        <th>{{ $t('reportCells_ItemsQty.localization_value.value') }}</th>
                        <th>{{ $t('reportCells_ItemsQtyAfter.localization_value.value') }}</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <template
                          v-for="(item, index) in $store.getters.getProductsReport">
                        <template v-for="(log, indexLog) in item.logs">
                          <tr
                              v-if="productId === log.product_id && cellId === log.cell_id"
                              :key="index + indexLog + 'n'"
                          >
  <!--                          <td>{{ log.cell.combo_name }}</td>-->
                            <td>
                              <ValueHelper
                                  v-if="item.order_model.length"
                                  :value="item.order_model[0].object.created_at"
                                  :date="true"
                                  :dateType="'DD MMM, YYYY'"
                              />
                            </td>
                            <td>
                              <TableUserColumn
                                  v-if="item.order_model.length"
                                  :item="item.order_model[0]"
                              />
                            </td>
                            <td>
                              {{ log.cell_quantity_before }}
                            </td>
                            <td>
                              {{ log.effected_quantity }}
                            </td>
                            <td>
                              {{ log.cell_quantity_after }}
                            </td>
                            <td>
                              <LinkButton
                                v-if="getTypeLink(item)"
                                :value="$t('reportCells_show.localization_value.value')"
                                :type="false"
                                :link="getTypeLink(item)"
                                :target="'_blank'"
                            />
                              <span v-else>{{$t('reportCells_relocate.localization_value.value')}}</span>
                              <!--                                :link="$store.getters.GET_PATHS.financeReportCells + '/' + cell.id + '/' + product.product_id"-->
                            </td>
                          </tr>
                        </template>
                      </template>
                      </tbody>
                    </table>

                  </div>

                  <div class="site-table-mobile"
                     v-if="isMobileFunc()"
                >
                  <div class="site-table-mobile__inner custom-row">
                    <template
                        v-for="(item, index) in $store.getters.getProductsReport">
                      <template v-for="(log, indexLog) in item.logs">
                        <div class="custom-col custom-col--50 custom-col--sm-100"
                             v-if="productId === log.product_id && cellId === log.cell_id"
                             :key="index + indexLog + 'n'">
                          <div class="site-table-mobile__item">
                            <div class="site-table-mobile__head">
                              {{ log.cell.combo_name }}
                            </div>
                            <div class="site-table-mobile__row custom-row">
                              <div class="site-table-mobile__piece custom-col">
                                {{ $t('reportCells_User.localization_value.value') }}
                                <span>
                                   <TableUserColumn
                                       v-if="item.order_model.length"
                                       :item="item.order_model[0]"
                                       :mobile="true"
                                   />
                                </span>
                              </div>
                            </div>
                            <div class="site-table-mobile__row custom-row">
                              <div class="site-table-mobile__piece custom-col">
                                {{ $t('reportCells_Date.localization_value.value') }}
                                <span>
                                  <ValueHelper
                                      v-if="item.order_model.length"
                                      :value="item.order_model[0].object.created_at"
                                      :date="true"
                                      :dateType="'DD MMM, YYYY'"
                                  />
                                </span>
                              </div>
                            </div>
                            <div class="site-table-mobile__row custom-row">
                              <div class="site-table-mobile__piece custom-col">
                                {{ $t('reportCells_ItemsQtyBefore.localization_value.value') }}
                                <span>
                                  <ValueHelper
                                      :value="log.cell_quantity_before"
                                      :replaceValue="'0'"
                                  />
                                </span>
                              </div>
                            </div>
                            <div class="site-table-mobile__row custom-row">
                              <div class="site-table-mobile__piece custom-col">
                                {{ $t('reportCells_ItemsQty.localization_value.value') }}
                                <span>
                                  <ValueHelper
                                      :value="log.effected_quantity"
                                      :replaceValue="'0'"
                                  />
                                </span>
                              </div>
                            </div>
                            <div class="site-table-mobile__row custom-row">
                              <div class="site-table-mobile__piece custom-col">
                                {{ $t('reportCells_ItemsQtyAfter.localization_value.value') }}
                                <span>
                                  <ValueHelper
                                      :value="log.cell_quantity_after"
                                      :replaceValue="'0'"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>

                  </div>
                </div>

                  <div class="table-bottom-btn">
                    <div class="table-bottom-btn__inner">
                      <ShowMore
                          v-if="$store.getters.getProductsReportCommonList.next_page_url !== null && $store.getters.getProductsReport.length > 0"
                          :count="null"
                          @click.native="showMore"
                      />
<!--                          :count="$store.getters.getProductsReportCommonList.total - $store.getters.getProductsReportForPage < $store.getters.getProductsReportForPage ?-->
<!--                    $store.getters.getProductsReportCommonList.total - $store.getters.getProductsReportForPage:-->
<!--                    $store.getters.getProductsReportForPage"-->
                    </div>
                  </div>

                </template>
                <template
                    v-if="$store.getters.getProductsReportLoading === false && $store.getters.getProductsReport.length === 0">
                  <NoResult
                      :countFilterParams="0"
                      :title="$t('common_noDataYet.localization_value.value')"
                      :titleWithFilter="$t('common_noResult.localization_value.value')"
                      :text="$t('common_noDataYetTxt.localization_value.value')"
                      :textWithFilter="$t('common_noResultSeems.localization_value.value')"
                      @resetFilter="$emit('resetFilter')"
                  />
                </template>


              </div>
            </div>


          </div>


        </template>
      </CardRightBlock>
    </div>
  </div>
</template>

<script>
import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import {mixinDetictingMobile} from "@/mixins/mobileFunctions";
import CardLeftBlock from "@/components/coreComponents/CardLeftBlock/CardLeftBlock";
import CardRightBlock from "@/components/coreComponents/CardRightBlock/CardRightBlock";
import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
import {ordersInboundMixin} from "@/mixins/ordersInbound/ordersInboundMixin";
import {FBMMixinsHelper} from "@/mixins/FBMMixins/FBMMixins";
import {consolidationMixinHelper} from "@/mixins/consolidationMixins/consolidationMixinHelper";
import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";
import NoResult from "@/components/coreComponents/NoResult/NoResult";
import ShowMore from "@/components/coreComponents/Buttons/ShowMore/ShowMore";

export default {
  name: "ReportCellsByProduct",
  components: {ShowMore, NoResult, LinkButton, CardRightBlock, CardLeftBlock, ValueHelper, TableUserColumn},

  mixins: [mixinDetictingMobile, routeFilter, FBMMixinsHelper, ordersInboundMixin, consolidationMixinHelper],

  computed: {
    getProductCountInfo() {
      return this.product?.productInstance?.count
    },

    getUserCellName() {
      let cellEntity = false
      let flag = false
      this.$store.getters.getProductsReport.map(item => {
        if(!flag && this._.find(item.logs, {product_id: this.productId, cell_id: this.cellId})) {
          cellEntity = this._.find(item.logs, {product_id: this.productId, cell_id: this.cellId})
          flag = true
        }
      })
      return cellEntity.cell.combo_name
    },

  },

  data() {
    return {
      forPage: this.$store.getters.getProductsReportForPage,
      page: 1,
      onePage: 1,
      filterGetParams: {},

      productId: null,
      cellReportItems: null,
      product: null,
      cellId: null,
    }
  },

  mounted() {
    this.productId = parseInt(this.$route.params.productId)
    this.cellId = parseInt(this.$route.params.cellId)
    this.filter().then(() => {
      if(this.$store.getters.getProductsReport.length > 0){
        let storageId = this.$store.getters.getProductsReport[0].logs[0].storage?.id
        this.initProduct(storageId)
      }
    })

  },


  methods: {

    getFilterParam() {
      this.filterGetParams = {
        page: this.$route.query.page,
        count: this.$route.query.count,
      }
    },

    async filter(next = false) {
      this.getFilterParam()

      let url

      // generate main filter if has get params in URL
      let generateMainFilter = this.generateMainFilterFromUrl(next, this)

      // generate other filter if has get params in URL
      this.generateParamsFilterFromUrl(this)

      // Generate filter URL
      url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
      )

      if (next) this.$store.commit('setNextProductsReportPage', true)
      return this.$store.dispatch('fetchProductsReportHistory', url).then(() => {
        this.$store.commit('setNextProductsReportPage', false)
        this.$store.commit('setGlobalUpdateTable', false)
      })

    },

    generateFilterUrl(page, forPage) {
      let myQuery = this.createQueryBuilderObject()

      let cellId = this.$route.params.cellId
      myQuery.where('cell_id', cellId)
      myQuery.where('product_id', this.productId)

      return this.generateGetParamsFromQuery(myQuery, forPage, page)
    },

    // initCellsReportShow() {
    //   let cellId = this.$route.params.cellId
    //   this.productId = parseInt(this.$route.params.productId)
    //
    //   let filter = `?filter[cell_id]=${cellId}&filter[product_id]=${this.productId}`
    //
    //   this.$store.dispatch('fetchProductsReportHistory', filter).then((response) => {
    //     let items = this.getRespPaginateData(response)
    //
    //     console.log(items);
    //     this.cellReportItems = items
    //   })
    // },

    initProduct(storageId) {
      this.$store.dispatch('getProducts', this.productId + `?storageId=${storageId}`).then((response) => {
        this.product = this.getRespData(response)
      })
    },

    getTypeLink(item) {
      if (item.order_model.length === 0) return false
      let object = item.order_model[0]?.object
      let deliveryServiceId = item.order_model[0]?.delivery_service?.id

      switch (item.operation_type) {
        case 'outbound' :
          if (!object.type) {
            return this.$store.getters.GET_PATHS.ordersFBA + '/show/' + object.id
          }
          return this.$store.getters.GET_PATHS.ordersFBM + '/show/' + this.getOrderFBMTypeById(object.type)?.name + '/' + object.id
        case 'inventory' :
          return this.$store.getters.GET_PATHS.mainSettingsInventory + '/show/' + object.id
        case 'inbound' :
          return this.$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
              this.getOrderType(object['fulfillment'], object['return']).value +
              '/' + object.id
        case 'disposal' :
          return this.$store.getters.GET_PATHS.warehouseDisposalOrders + '?id=' + object.id
        case 'consolidation_inbound' :
          return this.$store.getters.GET_PATHS.ordersConsolidationEditing + '/' +
              this.getConsolidationType({consolidation_destination: {delivery_service_id: deliveryServiceId}}) + '/' + object.id
      }
    },
  }

}
</script>

<style scoped>

</style>